import React, { useEffect, useState } from "react";
import "../scss/content.scss"
import {ModailCost} from "../jsx/modail"
// import Girl from "../img/girl.png"
import Logo from "../img/logo.png"
import LogoB from "../img/bodro.png"
import Kh from "../img/Ресурс 3-8.png"

export function Content(){
    const [iW,setIW] = useState(window.innerWidth)
    useEffect(()=>{
        window.onresize = ()=>{
            setIW(window.innerWidth)
        }
    })

    return(
        <div className="content">
            <div className="case-logo">
                <img src={LogoB} alt=""  className="logoB"/>
                <img src={Logo} alt="" className={iW>850? "logo": "logo logo__mini"}/>
            </div>
            <div className="content_midle">
                {
                    iW>850? <img className="img-screen" src={Kh} alt="img" />:""
                }
                <div className="case">
                    <span>Допоможи воїну <br/>України вистояти</span>
                    <ModailCost></ModailCost>
                    {
                        iW>850? "":<img className="img-screen" src={Kh} alt="img" />
                    }
                </div>
            </div>
        </div>
    )
}

export function Footer(){
    return(
        <footer className="footer">
            <div className="dop-info">
                {/* <span className="dop-info_title">ФОНД КОМПЕНСУЄ ВАРТІСТЬ ЛІКІВ</span><br /> */}
                <span className="dop-info_sub-title">Зібрані кошти будуть направлені на допомогу  у реабілітації постраждалим військовим України від агресії російської федерації. </span><br />
                <span className="dop-info_info">Як працює програма?</span>
                <ul className="dop-info_list">
                    <li>Ми надамо можливість кожному пораненому, який потребує одужання, отримати безкоштовну допомогу завдяки вашим пожертвам, і ми продовжуватимемо це робити на постійній основі.</li>
                    <li>Кошти, зібрані від донорів з усього світу, йдуть на закупівлю спеціального обладнання для реабілітації поранених військових в Україні. Ці покупки будуть здійснюватися за спеціальними благодійними цінами від міжнародних постачальників</li>
                    <li>До плану реабілітації в складних медичних випадках будуть залучені провідні фахівці з різних клінік світу.</li>
                    <li>Ми працюємо під керівництвом партнерської освітньої фундації «Українська освітня фундація» та амбасадорів, які допомагають у реалізації цього важливого проекту.</li>
                </ul>
            </div>
            <span className="footer_title"></span>
            <span className="footer_sub-title">©2022 , CO "CF Ukrainian Educational Foundation", Biedrība (BDR) "JK NAMS". All rights reserved.</span>
        </footer>
    )
}